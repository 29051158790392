import {TAPIEmployeeList, TAPIEmployeePatchDTO, TAPIEmployeePatchData, TAPITariffListResponse} from '@/types/api.type'
import axios, {InternalAxiosRequestConfig, type AxiosInstance} from 'axios'

export class ApiService {
  private _instance: AxiosInstance
  private _token: string
  private handleDispatcher: {(): unknown} | undefined

  constructor (baseURL: string, handleDispatcher?: {(): unknown}) {
    this._instance = axios.create({baseURL})
    this.handleDispatcher = handleDispatcher
    this._instance.interceptors.request.use(this.requestInterceptor.bind(this), null)
  }

  get isTokenExist () {
    return !!this._token
  }

  private requestInterceptor(config: InternalAxiosRequestConfig<any>) {
    this.handleDispatcher && this.handleDispatcher()

    return config
  }

  setToken (value: string | undefined) {
    if (!value) {
      return Promise.reject('Ошибка обновления токена')
    }

    this._token = `Bearer ${value}`
    this._instance.interceptors.request.use((config) => {
      config.headers.Authorization = this._token

      return config
    }, _ => Promise.reject(_))
  }

  public fetchEmployeesList (page: number = 1, pageSize: number = 50, term?: string) {
    return this._instance.get<TAPIEmployeeList>('/employees-settings', {
      params: {
        page: String(page),
        pageSize: String(pageSize),
        ...(term ? {term} : {})
      }
    })
  }

  public fetchTariffList () {
    return this._instance.get<TAPITariffListResponse>('/tariff')
  }

  public fetchPeriodList () {
    return this._instance.get<TAPITariffListResponse>('/period')
  }

  public patchEmployeeSettings (data: TAPIEmployeePatchDTO[]) {
    const _data = data.map(item => ({
      ...item,
      limit: Number(item.limit),
      tripLimitCnt: Number(item.tripLimitCnt)
    }))

    return this._instance.patch<TAPIEmployeePatchData>('/employees-settings', {
      data: _data
    })
  }
}
