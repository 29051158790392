// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../fonts/Onest/stylesheet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app *{font-family:Onest,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;outline:none;box-sizing:border-box}.app h1,.app h2,.app h3,.app h4,.app h5,.app h6,.app p,.app ul{margin:0}.app ul{padding:0}.app p{margin:0}.slide-down-enter-active,.slide-down-leave-active{transition:opacity .5s}.slide-down-enter,.slide-down-leave-to{opacity:0}.fade-enter-active,.fade-leave-active,.fade-move{transition:.16s cubic-bezier(.59,.12,.34,.95);transition-property:opacity,transform}.fade-enter,.fade-leave-to{opacity:0}.fade-enter-to{opacity:1}.swipe-enter-active,.swipe-leave-active,.swipe-move{transition:all .16s ease}.swipe-enter,.swipe-leave-to{opacity:0;transform:translateX(30px)}.swipe-active{position:absolute}@keyframes rotation{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
